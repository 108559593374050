import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInfinitySlider,
  COverflowTable,
  CSimpleCard,
  CTable,
  CSectTitle03,
  CFlowList,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  LBanquetContact,
} from '../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: (
              <>
                お料理・ケータリング
                <br className="u_sp" />
                サービス
              </>
            ),
            sub: <>CUISINE and CATERING SERVICE</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/catering/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/catering/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className="l_sect02 u_pt50">
        <LWrap>
          <h2 className="c_headingLv2 u_tac u_mb10">
            ロイヤルパークホテル自慢のお料理と
            <br className="u_sp" />
            質の高いサービスをお届けします。
          </h2>
          <p className="c_sectLead u_mb50">
            会議や展示会などのアフターパーティー、竣工披露のお祝いや記念行事のお集まりに、多彩なお料理はいかがですか。
            <br />
            ホテルならではの味とサービスもご一緒に心を込めてお手伝いさせていただきます。
            <br />
            会場のレイアウト、看板、お花、お料理、屋台、レセプタントの派遣など、お客様のご要望に応じてご満足いただけるパーティーを演出いたします。
          </p>
          <CSectTitle03 exClass="u_mb30" title="当日までの流れ" />
          <CFlowList
            data={[
              {
                title: <>お打合せ</>,
                text: (
                  <>
                    <p>
                      お料理やお飲み物の内容から会場セッティング、ご招待状、看板、レセプタント、お土産など、
                      <br />
                      さまざまなご手配が可能。配車係、サービススタッフ構成など、ホテルでのパーティー同様に専任スタッフがきめ細やかなお打合せをいたします。
                      <br />
                      ご人数、ご予算などお気軽にご相談ください。
                    </p>
                  </>
                ),
              },
              {
                title: <>現地確認</>,
                text: (
                  <>
                    <p>
                      配車スペース、お客様の導線、貴社ご担当者様へのアドバイス等、当日を想定してのご確認を現地にて行います。
                    </p>
                  </>
                ),
              },
              {
                title: <>前日から当日</>,
                text: (
                  <>
                    <p>
                      状況により前日から会場セッティング等を行うなど、滞りなく当日をお迎えいただけるよう、ご準備いたします。
                      <br />
                      Webからはお問合せのみ行っていただけます。（開催日の10日前まで承ります。）
                      <br />
                      お名前、ご人数、ご連絡方法（お電話番号、メールアドレス等）を必ずご記入のうえ、ご送信をお願い申しあげます。追ってホテルよりご連絡させていただきます。
                    </p>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
